<template>
  <v-dialog v-model="dialog" max-width="1024" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Preventa: {{ item?.Referencia || '-' }}</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row class="my-2">
          <v-col cols="12" md="4" class="">
            Titular:
            <strong class="ml-2">
              {{ item?.cliente || '-' }}
            </strong>
          </v-col>
          <v-col cols="12" md="4" class="">
            Vendedor:
            <strong class="ml-2">
              {{ item?.vendedor || '-' }}
            </strong>
          </v-col>
          <v-col cols="12" md="3" class="">
            Modelo:
            <strong class="ml-2">
              {{ item?.modelo || item?.modeloUnidad || '-' }}
            </strong>
          </v-col>
          <v-col cols="12" md="5" class="">
            Modelo Descripcion:
            <strong class="ml-2">
              {{ item?.modelo_descripcion || item?.DescripcionOperativa || '-' }}
            </strong>
          </v-col>
          <v-col cols="12" md="4" class="">
            Sucursal:
            <strong class="ml-2">
              {{ item?.sucursal || '-' }}
            </strong>
          </v-col>
          <v-col cols="12" md="3" class="">
            Total:
            <strong class="ml-2">
              {{ formatMonto(item?.precioventa)  || '-'}}
            </strong>
          </v-col>
        </v-row>

        <v-data-table
          class="cebra elevation-0 mt-2"
          item-key="id"
          sort-by="id"
          :headers="headers"
          :items="[]"
          :loading="load"
          sort-desc
          dense
          :search="search"
        >
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron detalles
            </v-alert>
          </template>
        </v-data-table>

        <div class="d-flex mt-3">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FechaPicker from '../util/FechaPicker.vue'
import { format_money_round } from '../../util/utils';

export default {
  data() {
    return {
      load: false,
      search: '',
      localEncabezados: [],

      filtro: {
        fecha_desde: ''
      },

      headers: [
        {
          text: 'Empresa',
          value: 'nombre_empresa'
        },
        {
          text: 'Nombre',
          value: 'Nombre_archivo'
        },
        { text: 'Fecha carga', value: 'Fecha_carga' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },

  methods: {
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },

    formatMonto(monto) {
      return format_money_round(monto);
    },

  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  props: {
    value: Boolean,
    item: {
      type: Object,
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
