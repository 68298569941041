<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>
        Generar liquidación
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-6">
          <v-col cols="5" v-if="!rango">
            <PeriodoPicker
              v-model="periodo"
              :label="true"
              @change="validarFecha()"
            />
          </v-col>
          <v-col cols="6" sm="3" md="4" v-if="rango">
            <FechaPicker
              v-model="desde"
              label="Desde"
              hide-details
              clearable
              @change="validarFecha()"
            />
          </v-col>
          <v-col cols="6" sm="3" md="4" v-if="rango">
            <FechaPicker
              v-model="hasta"
              label="Hasta"
              hide-details
              clearable
              @change="validarFecha()"
            />
          </v-col>
          <v-col cols="6" sm="3" md="3" class="pb-2">
            <v-checkbox
              label="Rango"
              v-model="rango"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn color="error" class="mr-2" @click="dialog = false">
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Generar"
          icono="fas fa-upload"
          :texto="`¿Está seguro que desea <strong>generar</strong> la liquidación para el periodo <strong>${periodo}</strong>?`"
          :disabled="!fechaValida"
          @action="generar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import PeriodoPicker from '../../util/PeriodoPicker.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import FechaPicker from '../../util/FechaPicker.vue'

export default {
  data() {
    return {
      familia: null,
      periodo: '',
      fechaValida: null,
      rango: false,
      desde: '',
      hasta: ''
    }
  },
  methods: {
    async generar() {
      if (
        (this.fechaValida &&
          this.desde != null &&
          this.hasta != null &&
          (this.desde.length > 0 || this.hasta.length > 0)) ||
        this.periodo.length > 0
      ) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('comisionesVN/generarLiquidacion', {
            periodo: this.periodo,
            rango: this.rango,
            desde: this.desde,
            hasta: this.hasta
          })
          .then(res => {
            if (res.exito) {
              this.dialog = false
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'El periodo seleccionado no es válido.',
          color: 'warning'
        })
      }
    },

    async validarFecha() {
      if (
        this.periodo.length > 0 ||
        this.desde.length > 0 ||
        this.hasta.length > 0
      ) {
        if (!this.rango) {
          await this.$store
            .dispatch('comisionesVN/validarPeriodo', {
              periodo: this.periodo
            })
            .then(res => {
              if (!res.exito) {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'warning'
                })
              }
              this.fechaValida = res.exito
            })
        } else {
          await this.$store
            .dispatch('comisionesVN/validarRango', {
              desde: this.desde,
              hasta: this.hasta
            })
            .then(res => {
              if (!res.exito) {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'warning'
                })
              }
              this.fechaValida = res.exito
            })
        }
      }

      if (
        this.periodo.length === 0 &&
        this.desde.length === 0 &&
        this.hasta.length === 0
      ) {
        this.fechaValida = false
      }
    }
  },

  computed: {
    ...mapState(['familias']),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  props: {
    value: Boolean,
    item: {
      type: Object
    }
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.familia = null
        this.periodo = ''
        this.fechaValida = false
        this.rango = false
        this.desde = ''
        this.hasta = ''
      }
    },

    rango(val) {
      this.periodo = ''
      this.desde = ''
      this.hasta = ''
    },

    desde(val) {
      if (val) {
      } else {
        this.desde = ''
      }
    },

    hasta(val) {
      if (val) {
      } else {
        this.hasta = ''
      }
    }
  },

  components: {
    PeriodoPicker,
    BtnConfirmar,
    FechaPicker
  }
}
</script>
