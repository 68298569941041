<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.empresa"
                      item-text="nombre"
                      return-object
                      hide-details
                      outlined
                      dense
                      :items="familias"
                      @change="getSucursales()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="filtro.sucursal"
                      item-text="Nombre"
                      item-value="SucursalDeVentaID"
                      return-object
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="sucursales"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="pt-1">
                    Preventa
                    <v-text-field
                      v-model="filtro.preventa"
                      clearable
                      item-value="id"
                      item-text="nombre"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" md="3" class="pt-1">
                    Vendedor
                    <v-text-field
                      v-model="filtro.vendedor"
                      clearable
                      item-value="id"
                      item-text="nombre"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="0" sm="0" md="2" class="py-1"> </v-col>

                  <v-col cols="12" sm="3" md="3" class="pt-1">
                    Titular
                    <v-text-field
                      v-model="filtro.titular"
                      clearable
                      item-value="id"
                      item-text="nombre"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-1"
                    md="3"
                    :md="typeof filtro.fechaVenta !== 'string' ? 6 : 3"
                  >
                    <FechaPickerRango
                      v-model="filtro.fechaVenta"
                      :limpiar.sync="limpiar_fecha"
                      titulo="Fecha preventa"
                      :rango_def="0"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-1"
                    md="3"
                    :md="typeof filtro.fechaPatentamiento !== 'string' ? 6 : 3"
                  >
                    <FechaPickerRango
                      v-model="filtro.fechaPatentamiento"
                      :limpiar.sync="limpiar_fecha2"
                      titulo="Fecha patentamiento"
                      :rango_def="0"
                      :left_hasta="true"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="pt-1"
                    md="3"
                    :md="typeof filtro.fechaEntrega !== 'string' ? 6 : 3"
                  >
                    <FechaPickerRango
                      :clearable="true"
                      v-model="filtro.fechaEntrega"
                      :limpiar.sync="limpiar_fecha3"
                      titulo="Fecha entrega"
                      :rango_def="0"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pt-0"
                    style="margin-top: -22px"
                  >
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- DATA TABLE -->

        <v-card class="mt-2">
          <v-data-table
            class="cebra elevation-0 mt-2"
            item-key="id"
            sort-by="id"
            :headers="headers"
            :items="ventasFiltradas"
            :loading="load"
            sort-desc
            dense
            :search="search"
          >
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3">
                  <SearchDataTable v-model="search" />
                </v-col>
              </v-row>
            </template>
            <template
              v-for="header in headers.filter(header =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>

            <template v-slot:[`item.precioventa`]="{ item }">
              {{ item.precioventa | formatMoney }}
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon
                color="info"
                title="Ver detalle"
                small
                right
                @click="verInfoPreventa(item)"
              >
                fas fa-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <InfoPreventa :item="preventaSeleccionada" v-model="abrirModalInfo" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import moment from 'moment'
import { format_date, format_money_round } from '../../util/utils'
import InfoPreventa from '../../components/comisiones-salon/InfoPreventa.vue'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      search: '',
      limpiar_fecha: false,
      limpiar_fecha2: false,
      limpiar_fecha3: false,
      abrirModalInfo: false,
      preventaSeleccionada: null,

      filtro: {
        empresa: {},
        sucursal: {},
        preventa: '',
        vendedor: '',
        titular: '',
        fechaVenta: '',
        fechaPatentamiento: '',
        fechaEntrega: ''
      },

      preventaDesde: null,
      preventaHasta: null,
      patentamientoDesde: null,
      patentamientoHasta: null,
      entregaDesde: null,
      entregaHasta: null,

      headers: [
        { text: 'Preventa', value: 'Referencia' },
        {
          text: 'Fecha preventa',
          value: 'fechadeventa',
          formatter: this.formatDate
        },
        { text: 'Patente', value: 'PATENTE' },
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Factura', value: 'Factura' },
        { text: 'Entrega', value: 'fechaEntregaFormat' },
        { text: 'Total', value: 'precioventa' },
        { text: 'Acciones', value: 'acciones' }
      ],
      sucursales: [],
      ventas: [],
      ventasFiltradas: []
    }
  },
  methods: {
    async buscar() {
      if (
        Object.keys(this.filtro.empresa).length === 0 ||
        (this.filtro.fechaVenta.length === 0 &&
          this.filtro.fechaEntrega.length === 0 &&
          this.filtro.fechaPatentamiento.length === 0)
      ) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar una empresa y fecha.',
          color: 'warning'
        })
        return
      }

      if (typeof this.filtro.fechaVenta !== 'string') {
        this.preventaDesde = this.filtro.fechaVenta.fecha_desde || null
        this.preventaHasta = this.filtro.fechaVenta.fecha_hasta || null
      }
      if (typeof this.filtro.fechaEntrega !== 'string') {
        this.entregaDesde = this.filtro.fechaEntrega.fecha_desde || null
        this.entregaHasta = this.filtro.fechaEntrega.fecha_hasta || null
      }
      if (typeof this.filtro.fechaPatentamiento !== 'string') {
        this.patentamientoDesde =
          this.filtro.fechaPatentamiento.fecha_desde || null
        this.patentamientoHasta =
          this.filtro.fechaPatentamiento.fecha_hasta || null
      }
      this.load = true
      await this.$store
        .dispatch('ventasSalon/getPreventas', {
          empresa: this.filtro.empresa.id,
          sucursal: this.filtro.sucursal?.SucursalDeVentaID || null,
          preventa: this.filtro.preventa,
          vendedor: this.filtro.vendedor,
          titular: this.filtro.titular,
          fechaPreventa: this.validarObjectDate(this.filtro.fechaVenta),
          preventaDesde: this.preventaDesde,
          preventaHasta: this.preventaHasta,
          fechaPatentamiento: this.validarObjectDate(
            this.filtro.fechaPatentamiento
          ),
          patentamientoDesde: this.patentamientoDesde,
          patentamientoHasta: this.patentamientoHasta,
          fechaEntrega: this.validarObjectDate(this.filtro.fechaEntrega),
          entregaDesde: this.entregaDesde,
          entregaHasta: this.entregaHasta
        })
        .then(res => {
          if (res.exito) {
            this.ventas = res.data
            this.ventasFiltradas = this.ventas.sort((a, b) => {
              return moment.utc(b.fechadeventa).diff(moment.utc(a.fechadeventa))
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error,
            color: 'error'
          })
        })
      this.load = false
    },

    formatDate(date, format){
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    },

    validarObjectDate(fecha) {
      return typeof fecha !== 'string' ? null : fecha
    },

    async getSucursales() {
      if (Object.keys(this.filtro.empresa).length === 0) return
      this.$store.state.loading = true
      await this.$store
        .dispatch('ventasSalon/getSucursalesDeVenta', this.filtro.empresa.id)
        .then(res => {
          if (res.exito) {
            this.sucursales = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },

    verInfoPreventa(item) {
      this.abrirModalInfo = true
      this.preventaSeleccionada = item
    },

    limpiarFiltro() {
      this.filtro = {
        empresa: {},
        sucursal: {},
        preventa: '',
        vendedor: '',
        titular: '',
        fechaVenta: '',
        fechaPatentamiento: '',
        fechaEntrega: ''
      }

      this.filtro.fechaVenta = ''
      this.filtro.fechaPatentamiento = ''
      this.filtro.fechaEntrega = ''
      this.limpiar_fecha = true
      this.limpiar_fecha2 = true
      this.limpiar_fecha3 = true
      this.preventaDesde = null
      this.preventaHasta = null
      this.patentamientoDesde = null
      this.patentamientoHasta = null
      this.entregaDesde = null
      this.entregaHasta = null
    },
  },

  computed: {
    ...mapState(['familias'])
  },

  filters: {
    formatMoney(value) {
      return format_money_round(value)
    }
  },
  async created() {},

  components: {
    FechaPicker,
    BtnFiltro,
    SearchDataTable,
    FechaPickerRango,
    InfoPreventa
  }
}
</script>
