<template>
  <v-dialog v-model="dialog" max-width="450">
    <v-card>
      <v-card-title >
        Eliminar liquidacion
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-6">
            <h4>¿Estas seguro de eliminar la liquidación para el periodo {{ periodo }}?</h4>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-3">
        <v-btn color="error" class="mr-2" @click="dialog = false">
          Cancelar
        </v-btn>

        <v-btn color="success" class="mr-2" @click="eliminarLiquidacion()">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../../util/BtnConfirmar.vue'

export default {
  data() {
    return {
      validationErrors: {}
    }
  },
  methods: {

    async eliminarLiquidacion() {
      this.$store.state.loading = true
      await this.$store
        .dispatch('comisionesVN/eliminarLiquidacion', {
          liquidaciones_id: this.liquidaciones_id
        })
        .then(res => {
          if (res.exito) {
            this.$swal.fire({
              icon: 'success',
              title: res.message
            })
            this.dialog = false
            this.$emit('buscar', true)
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })

      this.$store.state.loading = false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  async created() {},

  props: {
    value: Boolean,
    periodo: String,
    liquidaciones_id: Array
  },

  watch: {
    async dialog(val) {}
  },

  components: {
    BtnConfirmar
  }
}
</script>
