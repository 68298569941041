<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card>
      <v-card-title>
        Agregar detalle - {{ vendedor }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-6">
          <v-col cols="4">
            <v-autocomplete
              v-model="datos.familia"
              label="Empresa PV"
              item-value="id"
              item-text="nombre"
              :items="familias"
              hide-details
              outlined
              clearable
              dense
              @change="handleFamiliaChange"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="datos.marca"
              label="Marca"
              item-value="id"
              item-text="nombre"
              :items="
                empresas.filter(empresa => empresa.familia === datos.familia)
              "
              hide-details
              outlined
              clearable
              dense
              @change="handleFamiliaChange"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Preventa"
              v-model.trim="datos.preventa"
              hide-details
              outlined
              dense
              @blur="validarPreventa"
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Titular"
              v-model.trim="datos.titular"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Patente"
              v-model.trim="datos.patente"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Modelo base"
              v-model.trim="datos.modeloBase"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Modelo"
              v-model.trim="datos.modelo"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Modelo descripcion"
              v-model.trim="datos.modeloDescripcion"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-text-field
              label="Sucursal"
              v-model.trim="datos.sucursal"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="datos.concepto"
              label="Concepto"
              item-value="Id"
              item-text="Nombre"
              :items="conceptos"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="datos.tipopv"
              label="Tipo PV"
              item-value="nombre"
              item-text="nombre"
              :items="tiposPV"
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Comentario"
              v-model.trim="datos.comentario"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Valor venta"
              type="number"
              v-model="datos.valorVenta"
              hide-details
              outlined
              dense
              :prefix="'$'"
              readonly
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              label="Porcentaje"
              type="number"
              v-model="datos.porcentaje"
              outlined
              dense
              :prefix="'%'"
              :rules="[validarPorcentaje]"
              @blur="calcularComision"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              label="Importe"
              type="number"
              v-model="datos.importe"
              hide-details
              outlined
              dense
              :prefix="'$'"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-3">
        <v-btn color="error" class="mr-2" @click="dialog = false">
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Agregar"
          :texto="`¿Está seguro que desea <strong>agregar</strong> el detalle?`"
          :disabled="!preventaValida || !porcentajeValido"
          @action="agregarDetalle()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import PeriodoPicker from '../../util/PeriodoPicker.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import moment from 'moment'

export default {
  data() {
    return {
      datos: {
        familia: null,
        marca: null,
        preventa: '',
        importe: null,
        concepto: 1,
        titular: '',
        valorVenta: null,
        porcentaje: null,
        tipopv: '',
        patente: '',
        modelo: '',
        modeloBase: '',
        modeloDescripcion: '',
        comentario: '',
        sucursal: null,
        fechaEntrega: '',
        fechaPatentamiento: '',
        precioSugerido: null,
        precioBase: null,
        precioLista: null,
        precioListaOriginal: null,
        fechaFactura: '',
        origen: '',
        periodo: '',
        sucursal_id: null
      },
      periodoValido: null,
      preventaValida: false,
      porcentajeValido: false,

      vendedor: '',

      sucursales: [],

      tiposPV: [
        {
          nombre: 'O'
        },
        {
          nombre: 'U'
        }
      ],

      validationErrors: {}
    }
  },
  methods: {
    async agregarDetalle() {
      if (this.validarCampos()) {
        let liquidacion_id = null
        await this.$store
          .dispatch('comisionesVN/getLiquidacionXPeriodo', {
            periodo: this.periodo
          })
          .then(res => {
            if (res.exito) {
              liquidacion_id = res.data
            }
          })

        this.$store.state.loading = true

        const porcentaje = this.datos.porcentaje / 100
        await this.$store
          .dispatch('comisionesVN/agregarDetalle', {
            ...this.datos,
            Liquidacion_id: liquidacion_id,
            Vendedor_id: this.item.Vendedor_id,
            Vendedor: this.item.Vendedor,
            concepto: this.datos.concepto.Id,
            porcentaje,
            sucursal: this.datos.sucursal,
            sucursal_id: this.datos.sucursal_id
          })
          .then(res => {
            if (res.exito) {
              this.$emit('buscar', true)
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.dialog = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'warning'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Por favor, complete todos los campos requeridos.',
          color: 'warning'
        })
      }
    },

    async validarPreventa() {
      // TRAER PREVENTA EXISTENTE EN SAI QUE NO EXISTA EN DYCAR
      if (this.datos.familia) {
        if (this.datos.preventa.length > 0) {
          this.$store.state.loading = true
          await this.$store
            .dispatch('comisionesVN/validarPreventa', {
              familia_id: this.datos.familia,
              preventa: this.datos.preventa
            })
            .then(async res => {
              if (res.exito) {
                if (res.existeEnDycar === 0 && res.existeEnSai === 1) {
                  await this.$store
                    .dispatch('comisionesVN/getDatosPreventa', {
                      empresa_id: this.datos.familia,
                      preventa: this.datos.preventa
                    })
                    .then(res => {
                      if (res.exito) {
                        this.datos.titular = res.data.Titular
                        this.datos.patente = res.data.Patente
                        this.datos.modelo = res.data.Modelo
                        this.datos.modeloBase = res.data.modeloBase
                        this.datos.tipopv = res.data.TipoPv
                        this.datos.modeloDescripcion =
                          res.data.Modelo_descripcion
                        this.datos.sucursal = res.data.NombreSucursal
                        this.datos.sucursal_id = res.data.Sucursal

                        // this.datos.sucursal =
                        this.datos.valorVenta = res.data.PrecioVenta
                        this.datos.origen = res.data.Origen
                        this.datos.fechaFactura =
                          res.data.FacturaFecha &&
                          res.data.FacturaFecha.length > 0
                            ? moment(res.data.FacturaFecha).format('DD/MM/YYYY')
                            : null
                        this.datos.fechaPatentamiento =
                          res.data.FechaPatentamiento &&
                          res.data.FechaPatentamiento.length > 0
                            ? moment(res.data.FechaPatentamiento).format(
                                'DD/MM/YYYY'
                              )
                            : null
                        this.datos.fechaEntrega =
                          res.data.fechaDeEntrega &&
                          res.data.fechaDeEntrega.length > 0
                            ? moment(res.data.fechaDeEntrega).format(
                                'DD/MM/YYYY'
                              )
                            : null
                        this.datos.precioBase = res.data.precioBase
                        this.datos.precioLista = res.data.preciolista
                        this.datos.precioSugerido = res.data.PrecioSugerido
                        this.datos.precioListaOriginal =
                          res.data.PrecioDeListaOriginal
                        this.datos.periodo =
                          res.data.Periodo && res.data.Periodo.length > 0
                            ? moment(res.data.Periodo).format('DD/MM/YYYY')
                            : null
                      } else {
                        this.preventaValida = true
                      }
                    })
                  this.preventaValida = true
                }
              } else {
                this.preventaValida = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'warning'
                })
              }
            })
          this.$store.state.loading = false
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar una empresa.',
          color: 'warning'
        })
      }
    },

    async calcularComision() {
      const porcentaje = (this.datos.porcentaje / 100).toPrecision(1)
      this.$store.state.loading = true
      if (this.datos.familia && this.datos.preventa.length > 0) {
        if (this.porcentajeValido && this.datos.porcentaje != 0) {
          await this.$store
            .dispatch('comisionesVN/getComisionCalculada', {
              familia_id: this.datos.familia,
              preventa: this.datos.preventa,
              tipoPv: this.datos.tipopv,
              porcentaje: porcentaje
            })
            .then(res => {
              if (res.exito) {
                this.datos.importe = res.data.toFixed(2)
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar una empresa y preventa.',
          color: 'warning'
        })
      }
      this.$store.state.loading = false
    },

    validarCampos() {
      this.validationErrors = {}
      const requiredFields = [
        'familia',
        'marca',
        'preventa',
        'importe',
        'concepto',
        'titular',
        'valorVenta',
        'porcentaje',
        'tipopv',
        'sucursal'
      ]

      requiredFields.forEach(field => {
        if (!this.datos[field]) {
          this.$set(this.validationErrors, field, true)
        }
      })

      return Object.keys(this.validationErrors).length === 0
    },

    async handleFamiliaChange() {
      this.preventa = ''
      if (this.datos.familia === 1) {
        this.datos.marca = this.empresas.find(
          empresa => empresa.familia === 1
        )?.id
      }

      if (this.datos.familia) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('ventasSalon/getSucursalesDeVenta', this.datos.familia)
          .then(res => {
            if (res.exito) {
              this.sucursales = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },

    validarPorcentaje(value) {
      const numero = parseInt(value)
      if (numero < 0 || numero > 100) {
        this.porcentajeValido = false
        return 'El número debe estar entre 0 y 100'
      }
      this.porcentajeValido = true
      return true
    }
  },

  computed: {
    ...mapState(['familias', 'empresas']),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hasValidationErrors() {
      return Object.keys(this.validationErrors).length > 0
    }
  },

  async created() {},

  props: {
    value: Boolean,
    item: {
      type: Object
    },
    periodo: String,

    conceptos: Array
  },

  watch: {
    async dialog(val) {
      if (!val) {
        this.datos = {
          familia: null,
          marca: null,
          preventa: '',
          importe: null,
          periodo: '',
          concepto: null,
          titular: '',
          valorVenta: null,
          porcentaje: null,
          tipopv: '',
          patente: '',
          modelo: '',
          modeloBase: '',
          comentario: '',
          sucursal: null
        }
      } else {
        this.conceptos.length === 1
          ? (this.datos.concepto = this.conceptos[0])
          : null

        this.vendedor = this.item.nombreVendedor
      }
    }
  },

  components: {
    PeriodoPicker,
    BtnConfirmar
  }
}
</script>
