<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card>
      <v-card-title>
        Editar detalle
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-6">
          <v-col cols="4">
            <v-autocomplete
              v-model="datos.familia"
              label="Empresa PV"
              item-value="id"
              item-text="nombre"
              :items="familias"
              hide-details
              outlined
              clearable
              dense
              @change="handleFamiliaChange"
              readonly
              disabled
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="datos.marca"
              label="Marca"
              item-value="id"
              item-text="nombre"
              :items="
                empresas.filter(empresa => empresa.familia === datos.familia)
              "
              hide-details
              outlined
              clearable
              dense
              @change="handleFamiliaChange"
              readonly
              disabled
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Preventa"
              v-model.trim="datos.preventa"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Titular"
              v-model.trim="datos.titular"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Patente"
              v-model.trim="datos.patente"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Modelo base"
              v-model.trim="datos.modeloBase"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Modelo"
              v-model.trim="datos.modelo"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Modelo descripcion"
              v-model.trim="datos.modeloDescripcion"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-text-field
              label="Sucursal"
              v-model.trim="datos.sucursal"
              hide-details
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="datos.concepto"
              label="Concepto"
              item-value="Id"
              item-text="Nombre"
              :items="conceptos"
              hide-details
              clearable
              outlined
              dense
              readonly
              disabled
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="datos.tipopv"
              label="Tipo PV"
              item-value="nombre"
              item-text="nombre"
              :items="tiposPV"
              hide-details
              clearable
              outlined
              dense
              readonly
              disabled
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Comentario"
              v-model.trim="datos.comentario"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Valor venta"
              type="number"
              v-model="datos.valorVenta"
              hide-details
              outlined
              dense
              :prefix="'$'"
              readonly
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              label="Porcentaje"
              type="number"
              v-model="datos.porcentaje"
              outlined
              dense
              :rules="[validarPorcentaje]"
              :prefix="'%'"
              @blur="calcularComision"
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="4">
            <v-text-field
              label="Importe"
              type="number"
              v-model="datos.importe"
              hide-details
              outlined
              dense
              :prefix="'$'"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-3">
        <v-btn color="error" class="mr-2" @click="dialog = false">
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Editar"
          :texto="`¿Está seguro que desea <strong>editar</strong> el detalle?`"
          :disabled="!preventaValida && !porcentajeValido"
          @action="editarDetalle()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import PeriodoPicker from '../../util/PeriodoPicker.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import moment from 'moment'

export default {
  data() {
    return {
      datos: {
        familia: null,
        marca: null,
        preventa: '',
        importe: null,
        concepto: 1,
        titular: '',
        valorVenta: null,
        porcentaje: null,
        tipopv: '',
        patente: '',
        modelo: '',
        modeloBase: '',
        modeloDescripcion: '',
        comentario: '',
        sucursal: null,
        fechaEntrega: '',
        fechaPatentamiento: '',
        precioSugerido: null,
        precioBase: null,
        precioLista: null,
        precioListaOriginal: null,
        fechaFactura: '',
        origen: '',
        periodo: '',
        liquidacion_id: null,
        vendedor_id: null,
        importeAnterior: null
      },
      periodoValido: null,
      preventaValida: false,
      porcentajeValido: false,

      porcentajes: [],
      sucursales: [],

      tiposPV: [
        {
          nombre: 'O'
        },
        {
          nombre: 'U'
        }
      ],

      validationErrors: {}
    }
  },
  methods: {
    async editarDetalle() {
      if (this.validarCampos()) {
        this.$store.state.loading = true
        const porcentaje = (this.datos.porcentaje / 100).toPrecision(1)
        await this.$store
          .dispatch('comisionesVN/editarDetalle', {
            Liquidacion_id: this.datos.liquidacion_id,
            vendedor_id: this.item.Vendedor_id,
            importe: this.datos.importe,
            familia_id: this.datos.familia,
            preventa: this.datos.preventa,
            patente: this.datos.patente,
            comentario: this.datos.comentario,
            porcentaje,
            importeAnterior: this.datos.importeAnterior
          })
          .then(res => {
            if (res.exito) {
              this.$emit('buscar', true)
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.dialog = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'warning'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Por favor, complete todos los campos requeridos.',
          color: 'warning'
        })
      }
    },

    validarPorcentaje(value) {
      const numero = parseInt(value)
      if (numero < 0 || numero > 100 || isNaN(numero)) {
        this.porcentajeValido = false
        return 'El número debe estar entre 0 y 100'
      }
      this.porcentajeValido = true
      return true
    },

    validarCampos() {
      this.validationErrors = {}
      const requiredFields = [
        'familia',
        'marca',
        'preventa',
        'importe',
        'concepto',
        'titular',
        'valorVenta',
        'porcentaje',
        'tipopv',
        'sucursal'
      ]

      requiredFields.forEach(field => {
        if (!this.datos[field]) {
          this.$set(this.validationErrors, field, true)
        }
      })

      return Object.keys(this.validationErrors).length === 0
    },

    async handleFamiliaChange() {
      this.preventa = ''
      if (this.datos.familia === 1) {
        this.datos.marca = this.empresas.find(
          empresa => empresa.familia === 1
        )?.id
      }

      if (this.datos.familia) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('ventasSalon/getSucursalesDeVenta', this.datos.familia)
          .then(res => {
            if (res.exito) {
              this.sucursales = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },

    async calcularComision() {
      const porcentaje = (this.datos.porcentaje / 100).toPrecision(3)
      
      this.$store.state.loading = true
      if (this.porcentajeValido && this.datos.porcentaje != 0) {
        await this.$store
          .dispatch('comisionesVN/getComisionCalculada', {
            familia_id: this.datos.familia,
            preventa: this.datos.preventa,
            tipoPv: this.datos.tipopv,
            porcentaje: porcentaje
          })
          .then(res => {
            if (res.exito) {
              this.datos.importe = res.data.toFixed(2)
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
      }
      this.$store.state.loading = false
    }
  },

  computed: {
    ...mapState(['familias', 'empresas']),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hasValidationErrors() {
      return Object.keys(this.validationErrors).length > 0
    }
  },

  async created() {},

  props: {
    value: Boolean,
    item: {
      type: Object
    },
    periodo: String,

    conceptos: Array
  },

  watch: {
    async dialog(val) {
      if (!val) {
        this.datos = {
          familia: null,
          marca: null,
          preventa: '',
          importe: null,
          periodo: '',
          concepto: null,
          titular: '',
          valorVenta: null,
          porcentaje: null,
          tipopv: '',
          patente: '',
          modelo: '',
          modeloBase: '',
          comentario: '',
          sucursal: null
        }
      } else {
        const porcentaje = (this.item.Porcentaje * 100).toPrecision(1)
        const importe = this.item.Importe.toFixed(2)
        this.datos = {
          familia: this.item.Familia_id,
          marca: this.item.Empresa_id,
          preventa: this.item.Preventa,
          importe,
          periodo: '',
          concepto: this.item.Concepto_id,
          titular: this.item.Titular,
          valorVenta: this.item.Valor_venta,
          porcentaje: porcentaje,
          tipopv: this.item.Tipo_pv,
          patente: this.item.Patente,
          modelo: this.item.Modelo,
          modeloBase: this.item.ModeloBase,
          comentario: this.item.Comentario,
          sucursal: this.item.Sucursal,
          modeloDescripcion: this.item.Modelo_descripcion,
          liquidacion_id: this.item.Liquidacion_id,
          vendedor_id: this.item.Vendedor_id,
          importeAnterior: this.item.Importe
        }
      }
    }
  },

  components: {
    PeriodoPicker,
    BtnConfirmar
  }
}
</script>
